import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import SEO from '../components/seo';

import { Menu } from '../styles/styles.js';

const Item = styled.div`
  margin-bottom: 20px;
  .item-image {
    max-width: 350px;
    img {
      border-radius: 10px;
    }
  }
  @media (max-width: 499.98px) {
    background-color: #fafafa;
    padding: 15px;
    max-width: 350px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #eeeeee; */
  }
  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    .item-image {
      width: 250px;
      margin-right: 15px;
      flex: 0 0 250px;
      margin-left: 0;
    }
    .item-info-no-img {
      flex: 1;
    }
  }
  h4 {
    font-family: 'Roboto', 'sans-serif';
  }
  .item-separator {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-title {
    margin-right: 15px;
  }
  .item-price {
    color: #00803d;
  }
  .item-desc {
    color: #777;
    margin-top: 5px;
  }
  .item-options {
    margin-top: -15px;
    li {
      margin-bottom: 4px;
    }
  }
`;

const Porcoes = ({ data }) => {
  return (
    <>
      <SEO
        title="Pratos"
        keywords={[
          `Pratos`,
          `Batata`,
          `Batata com Bacon`,
          `filé de frango`,
          `Porção de Calabresa`,
        ]}
      />
      <Menu className="menu">
        <Slide left cascade>
          <h1 className="menu-title">Pratos Executivos</h1>
        </Slide>

        <Fade left>
          <div className="item-image">
            <Img
              fluid={data.pratos.childImageSharp.fluid}
              alt="Pratos vários sabores"
            />
          </div>
        </Fade>

        <Item>
          <div className="item-info-no-img">
            <div className="item-separator">
              <h4 className="item-title">Filé Bovino</h4>

              <div className="item-price-wrapper">
                <h4 className="item-price">39,90</h4>
              </div>
            </div>

            <p className="item-desc">
              Arroz, feijão, salada, filé e batata frita.
            </p>
          </div>
        </Item>

        <Item>
          <div className="item-info-no-img">
            <div className="item-separator">
              <h4 className="item-title">Filé de Tilápia</h4>

              <div className="item-price-wrapper">
                <h4 className="item-price">35,00</h4>
              </div>
            </div>

            <p className="item-desc">
              Arroz, feijão, salada, filé de tilápia e batata frita.
            </p>
          </div>
        </Item>

        <Item>
          <div className="item-info-no-img">
            <div className="item-separator">
              <h4 className="item-title">Panqueca com Strogonoff de Carne</h4>

              <div className="item-price-wrapper">
                <h4 className="item-price">35,00</h4>
              </div>
            </div>

            <p className="item-desc">
              Arroz, salada, batata frita e panqueca de strogonoff de carne.
            </p>
          </div>
        </Item>

        <Item>
          <div className="item-info-no-img">
            <div className="item-separator">
              <h4 className="item-title">Strogonoff de Carne</h4>

              <div className="item-price-wrapper">
                <h4 className="item-price">35,00</h4>
              </div>
            </div>

            <p className="item-desc">
              Arroz, salada, batata frita e strogonoff de carne.
            </p>
          </div>
        </Item>

        <Item>
          <div className="item-info-no-img">
            <div className="item-separator">
              <h4 className="item-title">Prato Kids</h4>

              <div className="item-price-wrapper">
                <h4 className="item-price">25,00</h4>
              </div>
            </div>

            <p className="item-desc">Arroz, filé e batata frita.</p>
          </div>
        </Item>

        <Item>
          <div className="item-info-no-img">
            <div className="item-separator">
              <h4 className="item-title">Adicionais</h4>
              <div className="item-price-wrapper">
                <p className="item-price"></p>
              </div>
            </div>
            <p className="item-desc" />
            <ul className="item-options">
              <li>Arroz 10,00</li>
              <li>Feijão 10,00</li>
              <li>Salada 10,00 </li>
              <li>Ovo frito 5,00</li>
              <li>Filé de tilápia 22,00</li>
              <li>Filé 22,00</li>
            </ul>
          </div>
        </Item>
      </Menu>
    </>
  );
};

export const query = graphql`
  query {
    pratos: file(relativePath: { eq: "pratos.png" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Porcoes;
